import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import react from '../assets/react.png';

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import profile2 from '../assets/profile2.png';
import circle from '../assets/circle.svg';


export default {
  email,
  mobile,
  css,
  figma,
  git,
  html,
  javascript,
  react,
  about01,
  about02,
  about03,
  about04,
  profile2,
  circle,
};