import React , { useState } from 'react'
import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper'
import { client } from '../../client'

import './Footer.scss'

const Footer = () => {
  return (
    <>
      <h2 className='head-text'>Discutons autour d'un café</h2>

      <div className='app__footer-cards'>
        <div className='app__footer-card'>
          <img src={images.email} alt='email' />
          <a href="mailto:ergyzenzo.dev@gmail.com" className="p-text">ergyzenzo.dev@gmail.com</a>
        </div>
        <div className='app__footer-card'>
          <img src={images.mobile} alt='mobile' />
          <a href="tel:33768428777" className="p-text">07 68 42 87 77</a>
        </div>
      </div>

      <div className='copyright'>
        <p className='p-text'>©2022 - Ergy Zenzo</p>
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg'
)