import React, { useState, useEffect } from 'react'
import './About.scss'
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';

// const abouts = [
//   { title: 'Développement Web', description: 'Je suis un développeur rigoureux et proactif.', imgUrl: images.about01 },
//   { title: 'Dev Frontend', description: 'Je développe avec passion de beaux sites et des applications web fonctionnelles', imgUrl: images.about02 },
//   { title: 'UI/UX', description: 'Je conçois des interfaces alliant ergonomie et esthétique', imgUrl: images.about03 },
//   { title: 'Référencement SEO', description: 'Avec moi, les bonnes pratiques SEO seront toujours utilisées', imgUrl: images.about04 }
// ]

const About = () => {

  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query)
    .then((data) => 
      setAbouts(data));
  }, []);
    
  
  return (
    <div className='app__about'>
      <h2 className='head-text'>
        Je sais qu'un <span>bon Développement</span><br />est<span> bon pour le business</span>
      </h2>

      <div className='app__profiles'>
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1}}
            whileHover={{ scale: 1.1}}
            transition={{ duration: 0.5, type: 'tween'}}
            className='app__profiles-item'
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className='bold-text' style={{ marginTop: 20 }}>{ about.title }</h2>
            <p className='p-text' style={{ marginTop: 10 }}>{ about.description }</p>

          </motion.div>
        ))}
      </div>
    </div>

  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  "app__whitebg"
)